import * as msal from "@azure/msal-browser";

// Requires environment variables to be loaded (see public/config.js)
// Authentication & authorization tasks are in App.js / getUserProfile()

// Initialize the msal
const msalConfig = {
  auth: {
    clientId: window.REACT_APP_APP_ID,
    redirectUri: window.REACT_APP_REDIRECT_URI,
    authority: window.REACT_APP_AUTHORITY,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};
const msalInstance = new msal.PublicClientApplication(msalConfig);

// Gets a user object in the browser cache
export function getCachedUser() {
  // In case multiple accounts exist, you can select
  const currentAccounts = msalInstance.getAllAccounts();
  if (window.REACT_APP_DEBUG)
    console.log("AzureAD getCachedUser: " + JSON.stringify(currentAccounts));

  var user = null;

  if (currentAccounts === null) {
    // no accounts detected
    return null;
  } else {
    user = currentAccounts[0];
  }

  return user;
}

// Fetch a token given a scope
export async function getToken(scopes) {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  const username = getCachedUser().username;
  const request = {
    scopes: scopes,
  };
  if (window.REACT_APP_DEBUG) console.log("AzureAD getCachedUser: " + request);
  request.account = msalInstance.getAccountByUsername(username);
  if (!request.account) {
    // if there's no cached account, time to go
    return;
  }

  return msalInstance.acquireTokenSilent(request).catch((error) => {
    console.warn(
      "silent token acquisition fails. acquiring token using interactive method"
    );
    if (error) {
      // fallback to interaction when silent call fails
      const errorCode = error.name;
      if (
        errorCode === "consent_required" ||
        errorCode === "interaction_required"
      ) {
        return msalInstance.acquireTokenRedirect(request);
      }
      console.warn(error);
    } else {
      console.warn(error);
    }
  });
}

// Login to Azure AD
export async function login() {
  if (window.REACT_APP_DEBUG) console.log("AzureAD login");

  const loginRequest = {
    scopes: ["openid", "profile", "user.read"],
  };

  await msalInstance
    .loginPopup(loginRequest)
    .then(function (loginResponse) {
      //login success

      // In case multiple accounts exist, you can select
      const currentAccounts = msalInstance.getAllAccounts();

      if (currentAccounts === null) {
        // no accounts detected
      } else if (currentAccounts.length > 1) {
        // Add choose account code here
      } else if (currentAccounts.length === 1) {
        var user = currentAccounts[0];
      }
      if (window.REACT_APP_DEBUG)
        console.log("AzureAD user: " + JSON.stringify(user));
      return user;
    })
    .catch(function (error) {
      //login failure
      if (window.REACT_APP_DEBUG) console.log("AzureAD error: " + error);
    });
}

// Logout of Azure AD
export async function logout() {
  const account = getCachedUser();
  if (window.REACT_APP_DEBUG) console.log("AzureAD account: " + account);
  const logoutRequest = {
    account: account,
  };
  return msalInstance.logout(logoutRequest);
}
